
import Vue from 'vue'

interface HeaderProps {
	title: String,
	asterisk: String,
	position: String,
}

export default Vue.extend<{}, {}, {}, HeaderProps>({
	name: 'HeaderBlock',
	props: {
		title: String,
		asterisk: String,
		position: String,
	},
	computed: {
		arrayTitle() {
			return this.title.split("\n");
		},
	}, 
	methods: {
		getAsteriskClasses(){
			var base = "hiq-section-asterisk--";
			var color = base + this.asterisk;
			var placing = this.position == 'left' ? base + 'left' : base + 'right';
			return placing + " " + color;
		}
	}
})
