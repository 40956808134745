import { Getter } from "vuex";
import { IState } from './state';

const title: Getter<IState, IState> = (state) => state.title;
const asterisk: Getter<IState, IState> = (state) => state.asterisk;
const position: Getter<IState, IState> = (state) => state.position;

export default {
	title,
	asterisk,
	position
}
