
import Vue from 'vue'
import HeaderBlock from './components/HeaderBlock.vue'

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	title: string,
	asterisk: string,
	position: string
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		title(){
			return this.$store.getters.title;
		},
		asterisk(){
			return this.$store.getters.asterisk;
		},
		position(){
			return this.$store.getters.position;
		}
	},
	components: {
		HeaderBlock,
	}
})
