export interface IState {
	title: string,
	asterisk: string,
	position: string,
}

const deafultState: IState = {
	title: '',
	asterisk: '',
	position: '',
}

export default deafultState;
